@import url('https://fonts.googleapis.com/css2?family=Cinzel+Decorative:wght@700&display=swap');

body {
  margin:0;
  font-size: 1rem;
  line-height: 1.5;
  font-family: 'Cinzel Decorative', cursive !important;
  color:#666666;
  background: #fff;
}


/* EXTRAS */
.mh-600{
  min-height:600px;
}
.fs-20{font-size:20px
}
.backyardsafari-pb{
  padding-bottom: 25px;
}

/* HEAD */
header {
  text-align: center;
  padding: 2%;
}
.logo{
  font-size: 170%;
  color: #444444;
  font-style: italic;
  /* text-decoration: none; */
}
/* .logo a:hover, .logo a:focus {
  color: #555555;
} */



/* MAIN */
.showcase{
  text-align:center;
  width:100%;
  height:auto;
  overflow:hidden;
}
.showcase img{
  width:100%;
  height:285px;
  border: 10px solid;
  border-image: radial-gradient(rgb(201,183,141), rgb(107,85,33)) 1;
}
@media (min-width: 576px) {
  .showcase img{
    height:400px;
  }
}
@media (min-width: 768px){
  .showcase img{
    width: 600px;
    height:450px;
  }
}
@media (min-width: 992px){
  .showcase img{
    width: 700px;
  }
}
.showcase-title{
  text-align:center;
  color:#994433;
  margin:15px auto;
}

.thumbnails{
  text-align:center;
  width:100%;
}

.thumbnail{
  position: relative;
  display:inline-block;
}
.thumbnail img{
  background-size: cover;
  width: 110px;
  height: 100px;

}
.thumbnail img:hover{
  opacity: 0.8;
  cursor: pointer;
}


/* IMAGE LOADING */
.smooth-image-wrapper {
  width: 100%;
  height: 100%;
  position: relative;
}
.smooth-image {
  transition: opacity 1s;
  width: 100%;
  height: 100%;
}
.image-visible {
  opacity: 1;
}
.image-hidden {
  opacity: 0;
}

.smooth-preloader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(245, 245, 245, 0.34);
}
.loader {
  background-image: linear-gradient(to right, #e6e6e6, #ffffff, #e6e6e6);
  width: 100%;
  height: 100%;
  display: inline-block;
  background-size: 200%;
  animation: preloader-block 2s linear 0s infinite reverse forwards;
}
@keyframes preloader-block {
  from {background-position: 0%, 0;}
  to {background-position: 170%, 0;}
}




/* BUTTON */
.button {
  cursor: pointer;
  font-weight: normal;
  line-height: normal;
  margin: 0 0 1.25rem;
  text-decoration: none;
  text-align: center;
  display: inline-block;
  padding: .2rem 3rem .2rem 3rem;
  font-size: 1.3rem;
  background-color: #994433;
  border-radius: 2px;
  color: white;
  transition: background-color 300ms ease-out;
  font-weight: normal !important;
}
.button:hover, .button:focus {
  color: #FFFFFF;
  background-color: #996633;
}


/* FOOT */
footer {
  text-align:center;
  width: 84%;
  margin: 2% 8%;
}
footer ul{
  margin:0;
  padding: 0;
}
footer li{
  display: inline-block;
  padding: 0 5px;
}
footer li a{
  color: #666666;
  text-decoration: none;
  font-weight: 900px;
  font-style: italic;
}
footer li a:hover{
  color:#333333;
}
footer li a small{
  color:#994433;
  font-size: .5rem;
}
